export const scriptCookies = {
	'es-CO': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/72dbe8b2-3c8b-4409-acba-f30a8ded4f92/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': '72dbe8b2-3c8b-4409-acba-f30a8ded4f92',
			async: 'true',
		},
	],
	'es-DO': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/4f8f4ff5-15f8-456d-bd21-9bb61948e10a/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': '4f8f4ff5-15f8-456d-bd21-9bb61948e10a',
			async: 'true',
		},
	],
	'es-EC': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/e3cebdbb-1998-4bbf-86ca-f468d49f5794/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': 'e3cebdbb-1998-4bbf-86ca-f468d49f5794',
			async: 'true',
		},
	],
	'es-PE': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/5b44a237-771f-4b0f-83b1-aa573e837d3e/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': '5b44a237-771f-4b0f-83b1-aa573e837d3e',
			async: 'true',
		},
	],
	'es-PR': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/0d0838d2-695b-418a-9ccc-c07afa7b9358/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': '0d0838d2-695b-418a-9ccc-c07afa7b9358',
			async: 'true',
		},
	],
	'es-AR': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/0b426b08-73aa-4b4a-a042-3a69b6560cf1/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': '0b426b08-73aa-4b4a-a042-3a69b6560cf1',
			async: 'true',
		},
	],
	'es-BO': [
		{
			type: 'text/javascript',
			src:
				'https://cdn-ukwest.onetrust.com/consent/1901b889-8ea4-486e-9629-55f9b43400e2/OtAutoBlock.js',
		},
		{
			type: 'text/javascript',
			src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
			'data-document-language': 'true',
			charset: 'UTF-8',
			'data-domain-script': '1901b889-8ea4-486e-9629-55f9b43400e2',
			async: 'true',
		},
	],
	es: [],
}
